import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/opt/build/repo/src/templates/page.jsx";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`Paralelná Polis Košice: ostrov slobody a nezávislosti.`}</MDXTag>
      </MDXTag>
      <MDXTag name="h2" components={components}>{`Kto sme?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Sme kolektív ľudí, ktorí chcú žiť v slobodnejšom a otvorenejšom svete. Hľadáme spôsoby a technológie (Bitcoin, blockchain, reputačné systémy a decentralizované technológie), ktoré nám otvárajú možnosti, zjednodušujú bežné procesy a odstraňujú bariéry. V Paralelnej Polis Košice chceme vytvoriť prostredie zamerané na vzdelávanie, diskusiu, objavovanie a tvorenie lepších systémov pre naše okolie - teba, nás, firmy, jednotlivcov - všetkých, ktorí majú záujem fungovať slobodnejšie a nezávislejšie. Zameriavame sa na experimentovanie a aplikáciu kryptomien a spojených technológií do bežného života.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Dnešné technológie a ich exponenciálny rozvoj priniesli veľké výhody ako pre jednotlivcov, tak pre firmy, ktoré sa týkajú hlavne väčšej slobody a otvorenosti, rýchlejších transakcií, nižších poplatkov, decentralizácie. No tieto sú pre väčšinu ľudí ešte neznáme. Naším cieľom je ich priblížiť, vysvetliť a pomôcť uplatniť v praxi. Máme ambíciu byť experimentátormi a inovátormi v týchto perspektívnych oblastiach.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Inšpirovali sme sa tímom `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://paralelnapolis.sk/"
        }}>{`Paralelnej Polis v Bratislave`}</MDXTag>{` a `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.paralelnipolis.cz/"
        }}>{`Paralelní Polis v Prahe`}</MDXTag>{` a týmto chceme túto myšlienku naším spôsobom priblížiť aj Košiciam a okoliu.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Filozofia / Vízia`}</MDXTag>
      <MDXTag name="p" components={components}>{`Našou víziou je svet, v ktorom majú ľudia možnosť odlúčiť sa od štátu a žiť v slobodnej spoločnosti. Vytvorenie priestoru, kde bude mať každý možnosť sa informovať, vzdelávať, rozvíjať a posúvať seba či svoj biznis vopred prostredníctvom nových technológií, kryptomien, slobodnejšej spoločnosti a podmienok pre prácu, podnikanie či kariéru.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Ako našu víziu realizujeme?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Našou hlavnou činnosťou je vzdelávanie a pomoc pri adopcii kryptomien a spojených technológií, ktoré umožnia slobodnejšie fungovanie jednotlivca aj spoločnosti ako takej. Našu víziu realizujeme prostredníctvom:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="strong" components={components} parentName="li">{`Prednášok`}</MDXTag>{` - u nás, u vás`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="strong" components={components} parentName="li">{`Technickej podpory`}</MDXTag>{` - zájdi k nám a radi vysvetlíme ako môžu
kryptomeny / blockchain pomôcť tvojmu projektu či biznisu`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="strong" components={components} parentName="li">{`Spolupráce`}</MDXTag>{` - umožňujeme tvorivcom a podnikavcom realizovať či
vyskúšať svoje nápady, pomáhame s ich aplikáciou alebo s adopciou
existujúcich technológií`}</MDXTag>
      </MDXTag>
      <MDXTag name="h2" components={components}>{`Hodnoty`}</MDXTag>
      <MDXTag name="p" components={components}>{`Sloboda je základná hodnota Paralelnej Polis, voluntarizmus, vzdelávanie (sa), aktívnosť/produkcia, kreativita/rast, otvorenosť, výsledky - naše akcie nemusia byť dokonalé. Podstatné je, aby boli. Lepšie spraviť niečo s chybami, ako to nespraviť vôbec. Sme si vedomí toho, že kde sa robí, tam lietajú triesky. Chyby sú súčasťou života a základom učenia sa. Prijímame ich s pokorou, nebojíme sa ich robiť. Preto ak niekto z nás spraví chybu pri tvorbe, nekritizujeme, ale pomáhame chybu napraviť a tak spolu rásť.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Uznávame len prirodzené autority, ktoré prirodzene vzniknú na základe odvedenej roboty, dodržaného slova a bežných interakcií. Očakávame pozitívnu aj negatívnu spätnú väzbu medzi členmi, rešpektujeme sa navzájom, počúvame sa. Sme ludia, takže konflikty prirodzene vznikajú, avšak našim cieľom je sa ich naučiť konštruktívne riešiť a nenechať ego vyhrať nad rozumom a srdcom!`}</MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      